var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('b-alert',{staticClass:"alert-fixed fixed-top rounded-1 m-4 bg-light alert-front",attrs:{"show":_vm.dismissCountDown,"variant":"success","dismissible":""},on:{"dismissed":function($event){_vm.dismissCountDown = 0},"dismiss-count-down":_vm.countDownChanged}},[_c('p',{staticClass:"float-end"},[_vm._v(_vm._s(_vm.dismissCountDown)+" วินาที...")]),_c('p',[_vm._v("แจ้งเตือน")]),_c('hr'),_c('p',[_vm._v(_vm._s(_vm.resalert))])]),_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" รหัสบุคคล : "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.personCode),expression:"personCode"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.personCode.$error,
                        },attrs:{"type":"text","placeholder":"รหัสบุคคล"},domProps:{"value":(_vm.personCode)},on:{"input":function($event){if($event.target.composing)return;_vm.personCode=$event.target.value}}}),(_vm.submitform && _vm.$v.personCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.personCode.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ชื่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.name.$error,
                        },model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(_vm.submitform && _vm.$v.name.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.name.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" นามสกุล (ไทย) : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.familyName.$error,
                        },model:{value:(_vm.familyName),callback:function ($$v) {_vm.familyName=$$v},expression:"familyName"}}),(_vm.submitform && _vm.$v.familyName.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.familyName.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" อีเมล : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.email.$error,
                        },model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.submitform && _vm.$v.email.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.email.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" เบอร์โทรศัพท์ : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.mobilePhone.$error,
                        },attrs:{"maxlength":"10"},model:{value:(_vm.mobilePhone),callback:function ($$v) {_vm.mobilePhone=$$v},expression:"mobilePhone"}}),(_vm.submitform && _vm.$v.mobilePhone.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.mobilePhone.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{staticClass:"mb-3",class:{
                          'is-invalid': _vm.submitform && _vm.$v.manageBranch.$error,
                        },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.manageBranch),callback:function ($$v) {_vm.manageBranch=$$v},expression:"manageBranch"}},[_vm._v("แก้ไขข้อมูลที่สาขา")]),(_vm.submitform && _vm.$v.manageBranch.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.manageBranch.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"success"},on:{"click":_vm.tooltipForm}},[_vm._v(" เสร็จสิ้น - กลับสู่หน้าหลัก ")])],1)])])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }